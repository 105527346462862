.oauth-button {
    background-image: url('../../images/3uni.ico') !important;
    background-size: 13% !important;
    background-repeat: no-repeat !important;
    background-position: 97% 75% !important;
    padding-right: 15% !important;
}

.oauth-button:hover {
    background-image: url('../../images/3uni.ico');
    background-size: 13%;
    background-repeat: no-repeat;
    background-position: 97% 75%;
    padding-right: 15%;
}